import "@adyen/adyen-web/dist/adyen.css";
import "react-loading-skeleton/dist/skeleton.css";
import './App.css';
import { MyAccount, Config, store } from '@cleeng/mediastore-sdk';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import HomePage from './pages/HomePage/HomePage';
import WinBack from './pages/WinBack/WinBack';
import Components from './pages/Components/Components';
import RedeemMyGift from './pages/RedeemMyGift/RedeemMyGift';
import Fluent from './pages/Fluent/Fluent';
import Home2 from './pages/Home2/Home2';
import Experience from './pages/Experience/Experience';
import SubscribeExperience from './pages/SubscribeExperience/SubscribeExperience';
import Trump from './pages/Trump/Trump';
import Header from './components/Header/Header';
import { Popups, usePopupsContext } from './components/Popups/Popups';
import $ from 'jquery';
import cfg from './Config';
import _ from './fluent-svc'; // set the fluent click id...

const url_account = `https://${cfg.domain}/account`;
const url_components = `https://${cfg.domain}/components`;

const App = () => {
  Config.setEnvironment(cfg.cleeng.environment);
  Config.setPublisher(cfg.cleeng.publisher_id);
  Config.setMyAccountUrl(url_account);
  Config.setCheckoutPayPalUrls({
    successUrl: url_components,
    cancelUrl: url_components,
    errorUrl: url_components
  });
  Config.setMyAccountPayPalUrls({
    successUrl: url_account,
    cancelUrl: url_account,
    errorUrl: url_account
  });
  Config.setTheme({
    fontColor: "#292525",
    backgroundColor: "#fff",
    cardColor: "#fff",
    successColor: "#435dc5",
    primaryColor: "#435dc5",
    loaderColor: "#cccccc",
    errorColor: "red",
    logoUrl: "link-to-the-logo"
  });
  Config.setVisibleAdyenPaymentMethods(cfg.cleeng.payment_methods);
  Config.setHidePayPal();
  Config.setLanguage("en");
  Config.setResetUrl(`${cfg.sso.url}/#/sso/reset-pwd?client_id=${cfg.sso.clientId}`,);

  const ModifyMyAccountFields = (props) => {
    // Wait for CC form to appear
    var selector = '.msd__profile__form #email';

    var emailFieldObserver = new MutationObserver(mutations => {
      if (window.document.querySelector(selector)) {
        if (window.location.pathname !== "/account")
          emailFieldObserver.disconnect();

        // Perform actions here!
        $('.msd__profile__form label[for=email]').hide();
        $('.msd__profile__form input#email').hide();

        // For account page consents, hide all checkboxes except for the Newsmax T&C
        var newsmaxCheckboxes = [];
        var cleengCheckboxes = [];

        $('.msd__profile-consents__checkbox').each(function () {
          var ariaLabel = $(this).attr('aria-label');
          if (ariaLabel && ariaLabel.includes('Terms and Conditions</a> of Newsmax')) {
            newsmaxCheckboxes.push($(this));
          } else if (ariaLabel && ariaLabel.includes('Terms and Conditions</a> of Cleeng')) {
            cleengCheckboxes.push($(this));
          } else {
            $(this).hide();
          }
        });

        newsmaxCheckboxes.forEach(function (checkbox) {
          checkbox.show();
        });

        cleengCheckboxes.forEach(function (checkbox) {
          checkbox.show();
        });
      }
    });

    emailFieldObserver.observe(window.document.body, {
      childList: true,
      subtree: true
    });

    // Phone validation
    var observer = new MutationObserver(function (observer) {
      const input = $(document).find('input#phoneNumber');
      const description = $(document).find('#phoneNumber-desc');
      const button = $(document).find('button.msd__button--confirm');

      input.on('blur', function () {
        var phoneNumberValue = $(this).val();
        var phoneNumberPattern = /^[2-9]\d{9}$/;

        if (!phoneNumberValue) {
          description.hide();
          button.removeAttr('disabled');
        } else if (!phoneNumberPattern.test(phoneNumberValue)) {
          description.text('Please enter a valid phone number.');
          description.show();
          button.attr('disabled', 'disabled');
        } else {
          description.hide();
          button.removeAttr('disabled');
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
  };



  const ModifyPlanDetails = (props) => {
    const { showModal } = usePopupsContext();
    var selector = '.msd__account-content';
    var planDetailsFieldObserver = new MutationObserver(mutations => {
      if (window.document.querySelector(selector)) {
        if (window.location.pathname !== "/account")
          planDetailsFieldObserver.disconnect();

        // Perform actions here!
        if ($('.msd__account-content .msd__info-box__title:contains("No offers yet!")').length > 0) {
          // Replace content of <main class="sc-lnPyaJ grrCLp msd__account-content">
          $('.msd__info-box__title').parent().parent().html(`
          <div class="row py-5" id="startNMPlus">
              <div class="col-12 col-xl-8 offset-xl-2 col-xxl-8 offset-xxl-2 pt-4 pb-5">
                  <h3 class="mb-4">You do not have an active Newsmax+ subscription</h3>
                  <h4 class="mb-3 fw-semibold">Choose a subscription option below. First-time subscribers will receive a 15-day <span class="text-uppercase text-danger">FREE</span> trial – no commitment, cancel anytime.</h4>
                  <div class="row">
                      <div class="col-xl-12 col-xxl-10 offset-xxl-1">
                          <div class="row gx-5 mt-4 equal-height">
                              <div class="col-md-6 col-xl-6 sm-mb-3">
                                  <div class="signUpBox rounded p-4">
                                      <h3>Newsmax+</h3>
                                      <h4 class="price ms-3">$4.99 / month</h4>
                                      <h5 class="promo">&nbsp;</h5>
                                      <ul>
                                          <li>Exclusive Content</li>
                                          <li>Breaking News and Expert Analysis</li>
                                          <li>Affordable Pricing</li>
                                          <li>Access From Anywhere, Any Device</li>
                                          <li>15-Day Trial</li>
                                          <li class="invisible">&nbsp;</li>
                                      </ul>
                                      <div class="text-center p-1">
                                      <button
                                          id="monthlySub"
                                          class="btn btn-light btn-lg"
                                          
                                        >
                                          SIGN UP NOW
                                      </button>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6 col-xl-6">
                                  <div class="signUpBox rounded p-4">
                                      <h3>Newsmax+</h3>
                                      <h4 class="price ms-3">$49.99 / year</h4>
                                      <h5 class="promo ps-5">Save $10!</h5>
                                      <ul>
                                          <li>Exclusive Content</li>
                                          <li>Breaking News and Expert Analysis</li>
                                          <li>Affordable Pricing</li>
                                          <li>Access From Anywhere, Any Device</li>
                                          <li>15-Day Trial</li>
                                          <li>Lower Annual Price</li>
                                      </ul>
                                      <div class="text-center p-1">
                                      <button
                                      id="annualSub"
                                      class="btn btn-light btn-lg"
                                      
                                      >
                                          SIGN UP NOW
                                      </button>
                                      </div>
                                  </div>
                              </div>
                              <br>
                              <h4><a href="https://support.newsmaxplus.com/" target="_blank" class="text-black">Need Help? Visit our Help Center for FAQs</a></h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      `);
          $('#monthlySub').on('click', function () {
            showModal('checkout_monthly');
          });
          $('#annualSub').on('click', function () {
            showModal('checkout_annually');
          });
        }
      }
    });

    planDetailsFieldObserver.observe(window.document.body, {
      childList: true,
      subtree: true
    });
  };

  const executeValidationScript = () => {
    const observer = new MutationObserver(function (observer) {
      const input = $(document).find('input#Mobile\\ phone');
      const description = $(document).find('#Mobile\\ phone-desc');
      const button = $(document).find('button.msd__button--confirm');

      input.on('blur', function () {
        var phoneValue = $(this).val();
        var phonePattern = /^[2-9]\d{9}$/;

        if (!phoneValue) {
          description.hide();
          button.removeAttr('disabled');
        } else if (!phonePattern.test(phoneValue)) {
          description.text('Please enter a valid phone number.');
          description.show();
          button.attr('disabled', 'disabled');
        } else {
          description.hide();
          button.removeAttr('disabled');
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
  };


  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <main>
            <Popups>
              <Switch>
                <Route exact path="/" component={() => <Experience />} />
                <Route exact path="/annual" component={() => <HomePage />} />
                <Route exact path="/freemonth" component={() => <HomePage />} />
                <Route exact path="/subscribe" component={() => <WinBack />} />
                <Route exact path="/fluent" component={() => <Fluent />} />
                <Route exact path="/experience" component={() => <Experience />} />
                <Route exact path="/experienceii" component={() => <Home2 />} />
                <Route exact path="/subscribeexperience" component={() => <SubscribeExperience />} />
                <Route exact path="/trump" component={() => <Trump />} />
                <Route exact path="/components" component={() => <Components />} />
                <Route exact path="/redeem-gift" component={() => <RedeemMyGift />} />
                <Route
                  path="/account"
                  component={({ match }) => (
                    <div className="myAccWrapper">
                      <Header />
                      <MyAccount />
                      {(ModifyMyAccountFields(), ModifyPlanDetails())}
                    </div>
                  )} />
              </Switch>
            </Popups>
            {(executeValidationScript())}
          </main>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
